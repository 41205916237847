import React from "react";

import Input from "../../components/Forms/Input";
import SubmitButton from "../../components/Forms/SubmitButton";

import SpecialtyDropdown from "../../components/Forms/SpecialtyDropdown";
import DesignationDropdown from "../../components/Forms/DesignationDropdown";

import { Page } from "../../components/Page";
import FormContainer from "../../components/Forms/FormContainer";

import InfoBubbleLink from "../../components/Forms/InfoBubbleLink";
import InfoIcon from "../../images/info-icon.svg";

import { eventTracker, pageTracker } from "../../tracker";

import "./styles.scss";

const ContactInfo = () => {
  React.useEffect(() => {
    pageTracker({
      pageTitle: "Request KOL > Contact Information (Step 2/4)",
      pageSubject: "Meeting Request",
    });

    eventTracker({
      eventAction: "Viewed Step",
      eventCategory: "Request KOL Form",
      eventLabel: "Step 2/4 - Contact Information",
    });
  }, []);

  return (
    <Page>
      <FormContainer>
        <ContactPageaMarkup />
      </FormContainer>
    </Page>
  );
};

const ContactPageaMarkup = () => {
  return (
    <div className="form-page--contact-page">
      <h1 className="form-fields--headline">
        Tell us a little bit about yourself
      </h1>

      <p className="form-fields--note body-text--small">All fields required</p>

      <div className="form-fields--container">
        <Input
          label="First Name"
          type="text"
          name="firstName"
          errorMessage="Please enter your first name."
        />
        <Input
          label="Last Name"
          type="text"
          name="lastName"
          errorMessage="Please enter your last name."
        />
        <Input
          label="Email Address"
          type="email"
          name="email"
          errorMessage="Please enter a valid email address."
        />
        <Input
          label="Phone Number"
          type="tel"
          name="phone"
          errorMessage="Please enter a phone number."
        />
        <SpecialtyDropdown />
        <DesignationDropdown />
        <Input
          errorMessage="Please enter a zip code."
          label="Zip code of practice location"
          type="string"
          name="zip"
        />
        <Input
          label="NPI number"
          type="text"
          name="npi"
          errorMessage="Please enter an NPI number."
          infobubble={
            <span className="form-input__info-link">
              <img src={InfoIcon} />
              <InfoBubbleLink
                trackingLabel="NPI Number"
                label="Why do we need this?"
                text="Your National Provider Identifier (NPI) number is important in verifying that you’re a practicing healthcare provider who is eligible for a session. Reach out to your GBT Sickle Cell Therapeutic Specialist if you don’t have an NPI number."
              />
            </span>
          }
        />
      </div>

      <SubmitButton
        analytics={{
          subject: "Meeting Request",
          label: "Request KOL - Step 3/4 - Next",
          action: "Clicked",
          category: "Internal Links",
        }}
        text="Next"
      />
    </div>
  );
};

export default ContactInfo;
